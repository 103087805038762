
export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },

    {
        title: 'Document Category',
        key: 'DocumentCategory',
        minWidth: 80,
    },
	{
        title: 'Document Type',
        key: 'description',
        minWidth: 80,
    },

]
